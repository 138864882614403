export class Avatar {
	private colors: string[] = ["#A18B36", "#bc34cb", "#A358DF", "#F65F7C", "#258750", "#E1C34C", "#ce2b2b", "#502fe5", "#290bc5", "#3f3448", "#f58033", "#80d21d"];

	constructor(private chatId: string) {
		if (!this.colorFromLocal) {
			this.setColorToLocal();
		}
	}

	private get localKey(): string {
		return `color-user-${this.chatId}`;
	}

	private get randomColor(): string {
		return this.colors[Math.floor(Math.random() * this.colors.length)];
	}

	private setColorToLocal(): void {
		localStorage.setItem(this.localKey, this.randomColor);
	}

	get colorFromLocal(): string | null {
		try {
			return localStorage.getItem(this.localKey);
		} catch (e) {
			console.error("Ошибка при доступе к localStorage", e);
			return null;
		}
	}
}
