<template>
	<div
		:class="[`user-avatar user-avatar--${size}`, { 'user-avatar--border': border }]"
		:style="avatarStyles"
		:title="fullName">
		<span class="user-avatar__initials">{{ initials }}</span>
	</div>
</template>

<script setup lang="ts">
	import { getInitials } from "@/utils/initials";
	import { Avatar } from "@/assets/classes/avatar/Avatar";

	const {
		fullName,
		userId,
		border = false,
		color = "var(--red-1)",
		size = "lg",
	} = defineProps<{
		fullName: string;
		border?: boolean;
		color?: string;
		size?: "md" | "lg" | "xl";
		userId: string;
	}>();

	const initials = computed(() => getInitials(fullName));

	const avatar = new Avatar(userId);

	const avatarStyles = computed(() => ({
		backgroundColor: avatar.colorFromLocal ?? color,
	}));
</script>

<style lang="scss" scoped>
	@import "user-avatar";
</style>
